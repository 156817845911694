<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-12-07 10:32:49
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-12-07 11:11:14
 * @FilePath: /dataview-viewer-test/src/views/login/LoginError.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="page-error">
    <article class="error-main">
      <img src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/910e6c2f4e5aaa75336dceabff055812.png" alt="登录失败" class="empty-img">
      <p class="desc">暂无可用架构，请联系平台管理员</p>
      <a href="javascript:;" class="back" @click="onBack">返回登录</a>
    </article>
  </div>
</template>

<script>
export default {
  name: 'PageError',
  methods: {
    onBack() {
      this.$router.replace({
        path: '/login'
      });
    }
  }
}
</script>

<style lang="less" scoped>
.page-error {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .error-main {
    .empty-img {
      display: block;
      margin: 0 auto;
      width: 404px;
      height: 255px;
    }
    .desc {
      margin-top: 25px;
      line-height: 36px;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      color: #4B8AF1;
    }
    .back {
      display: block;
      margin: 91px auto;
      margin-bottom: 0;
      width: 282px;
      height: 48px;
      background: var(--el-color-primary);
      border-radius: 4px;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      line-height: 48px;
      text-align: center;
      color: #ffffff;
    }
  }
}
</style>